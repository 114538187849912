import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import ContentBlocks from '../components/ContentBlocks'
import SectionHead from '../components/SectionHead'

export const BlogPostTemplate = ({
  content,
  title,
  date,
  children
}) => {
  return (
    <section>
      <SectionHead title={title} />
      <div className="container content">
       {children}
      </div>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  title: PropTypes.string,
}

const BlogPost = ({ data }) => {
  const { wordpressPost: post } = data
  return (
    <div>
      <Helmet title={`${post.title} | Q Contemporary`} />
      <BlogPostTemplate
        content={post.content}
        categories={post.categories}
        tags={post.tags}
        title={post.title}
        date={post.date}
      >
      { post.acf.content_blocks_post
        ?  <ContentBlocks content={post.acf.content_blocks_post} />
        : false
      }
      </BlogPostTemplate>  
    </div>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  fragment PostFields on wordpress__POST {
    id
    slug
    content
    date(formatString: "MMMM DD, YYYY")
    title
  }
  query BlogPostByID($id: String!) {
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      content
      date(formatString: "MMMM DD, YYYY")
      categories {
        name
        slug
      }
      tags {
        name
        slug
      }
      acf {
        content_blocks_post {
          ... on WordPressAcf_text_block {
            __typename
            width
            text
          }
          ... on WordPressAcf_image_block {
            __typename
            width
            image {
              caption
              acf {
                copyright
              }
              id
              localFile {
                childImageSharp {
                    # Try editing the "maxWidth" value to generate resized images.
                    fluid(maxWidth: 1680) {
                      ...GatsbyImageSharpFluid
                    }
                }
           	  }
            }
          }
          ... on WordPressAcf_gallery_block {
            __typename
            width
            gallery {
              acf {
                copyright
              }
              caption
              alt_text
              id
              localFile {
                id
                childImageSharp {
                  # Try editing the "maxWidth" value to generate resized images.
                  fluid(maxWidth: 1680) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
